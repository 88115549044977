import { Link } from 'react-router-dom'
import useFetch from 'src/hooks/useFetch'
import {useTranslation} from "react-i18next";

const FooterMiddle = () => {
  const { data } = useFetch(`/footer`);
  const {t} = useTranslation();
  
  
  return (
    <div className="footer-mid">
      <div className="content">
        <div className="footer-links">
          <div className="footer-link-col">
            <h6>{t('FooterMiddle.Companies')}</h6>
            <Link to="/about-us">{t('Header.AboutUs')}</Link>
            <Link to="/contact-us">{t('Header.Contact')}</Link>
            <Link to="/help-center">{t('Header.HelpCenter')}</Link>
          </div>
          <div className="footer-link-col">
            <h6>{t('FooterMiddle.Policies')}</h6>
            <Link to="/terms-of-use">{t('FooterMiddle.TermsOfUse')}</Link>
            <Link to="/privacy-policy">{t('FooterMiddle.PrivacyPolicy')}</Link>
            <Link to="/cookie-policy">{t('FooterMiddle.CookiePolicy')}</Link>
            <Link to="/aml-kyc-policy">{t('FooterMiddle.AmlKycPolicy')}</Link>
          </div>
          {/* <div className="footer-link-col">
            <Link to="">Log in</Link>
            <Link to="/buy-crypto">Buy crypto</Link>
          </div>
          <div className="footer-link-col">
            <Link to={data?.attributes.linkedinUrl} target="_blank">
              LinkedIn
            </Link>
            <Link to={data?.attributes.facebookUrl} target="_blank">
              Facebook
            </Link>
            <Link to={data?.attributes.instagramUrl} target="_blank">
              Instagram
            </Link>
          </div> */}
        </div>
        <div className="footer-subsc">
          <div>
            <label>{t('FooterMiddle.SubscribeNewsLetter')}</label>
            <div className="subsc-field">
              <input type="text" placeholder="Email" />
              <button>{t('FooterMiddle.Subscribe')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterMiddle

import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'src/hooks/storeHooks'

import Loading from './Loading/Loading'

import { TransactionInitRequest } from 'src/models/TransactionInitRequest'

import { getAsync, postAsync } from 'src/api/httpClient'
import { TransactionInitResponse } from 'src/models/TransactionInitResponse'
import { PaymentInitRequest } from 'src/models/PaymentInitRequest'
import { PaymentInitResponse } from 'src/models/PaymentInitResponse'
import { FiatToCryptoRate } from 'src/models/FiatToCrypto'
import { RATE_REFRESH_INTERVAL } from 'src/constants/constants'
import HoldTransaction from 'src/components/HoldTransaction'
import {RejectedStatusCode, TransactionHoldStatusCode} from 'src/CustomStatusCodes'
import TransactionResultModal from './TransactionResultModal'
import TransactionRejectModal from "src/components/TransactionRejectModal";
import {useTranslation} from "react-i18next";

const ExchangeConfirmation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tranIdError, setTranIdError] = useState<boolean>(false)
  const [transactionIsHolded, setTransactionIsHolded] = useState<boolean>(false)
  const [transactionRejected, setTransactionRejected] = useState<boolean>(false);
  const navigate = useNavigate()

  const [cryptoAmount, setCryptoAmount] = useState<string>('')
  const [rate, setRate] = useState<number>(0)
  const [offerId, setOfferId] = useState<string>('')

  const [remainingTime, setRemainingTime] = useState(RATE_REFRESH_INTERVAL)

  const createdOtp = useAppSelector((store) => store.notifications.createdOtp)
  const fiatToCrypto = useAppSelector(
    (store) => store.fiatToCrypto.fiatToCrypto
  )

  const fiatToCryptoRate = useAppSelector(
    (store) => store.fiatToCryptoRate.fiatToCryptoRate
  )

  const wallet = useAppSelector((store) => store.wallet.wallet)
  const email = useAppSelector((store) => store.clients.client?.email)
  const {t} = useTranslation();

  const submitHandler = async () => {
    setIsLoading(true)

    const tranId = await createTransaction()

    if (tranId === undefined) {
      setIsLoading(false)
      setTranIdError(true)
      return
    }

    const redirectUrl = await createPayment(tranId)

    redirectTo(redirectUrl)
    setIsLoading(false)
  }

  const createTransaction = async () => {
    console.log(createdOtp)
    const transactionInit: TransactionInitRequest = {
      crypto: fiatToCrypto?.cryptoCurrency,
      fiat: fiatToCrypto?.fiatCurrency,
      fiatAmount: fiatToCrypto?.fiatAmount,
      email: email,
      offerId: offerId,
      walletAddress: wallet?.walletAddress,
      notificationId: createdOtp?.id,
    }

    const tranInitResult = await postAsync<
      TransactionInitRequest,
      TransactionInitResponse
    >('transactions/init', transactionInit)

    if (tranInitResult.statusCode === TransactionHoldStatusCode) {
      setTransactionIsHolded(true)
    }
    
    if (tranInitResult.statusCode === RejectedStatusCode){
      setTransactionRejected(true);
    }

    return tranInitResult.data.id
  }

  const createPayment = async (transactionId?: string) => {
    const result = await postAsync<PaymentInitRequest, PaymentInitResponse>(
      'payments',
      {
        transactionId,
      }
    )

    return result.data.result.redirectUrl
  }

  const redirectTo = (redirectUrl: string) => {
    window.location.href = redirectUrl
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prev) => prev - 1)
    }, 1000)

    if (remainingTime === 0) {
      refreshData()
      setRemainingTime(RATE_REFRESH_INTERVAL)
    }

    return () => clearInterval(intervalId)
  }, [remainingTime])

  useEffect(() => {
    ;(async () => {
      await refreshData()
    })()
  }, [])

  const refreshData = async () => {
    const newFiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${fiatToCrypto?.fiatCurrency}&crypto=${fiatToCrypto?.cryptoCurrency}`
    )

    if (fiatToCrypto?.fiatAmount === undefined) return

    setCryptoAmount(
      ((fiatToCrypto?.fiatAmount - fiatToCrypto.fiatFee) / newFiatToCrypto.data.rate).toFixed(
        newFiatToCrypto.data.cryptoAccuracy
      )
    )
    setRate(newFiatToCrypto.data.rate)
    setOfferId(newFiatToCrypto.data.offerId)
  }

  return (
    <>
      <div>
        <div className="back-fifth-btn back-btn" onClick={() => navigate(-1)}>
          <img src="/img/arr-left.svg" alt="Arrow Left" />
          {t('ExchangeConfirmation.Summary')}
        </div>
        <div className="summary-pane">
          <div className="sec-field">
            <label>{t('ExchangeConfirmation.Fiat')}</label>
            <div className="oth-field">
              <div className="field-with-btc">{fiatToCryptoRate?.fiat}</div>
              <input
                type="text"
                value={
                  +(fiatToCrypto?.fiatAmount ?? 0)
                }
                readOnly
              />
            </div>
          </div>
          <div className="sec-field">
            <label>{t('ExchangeConfirmation.Crypto')}</label>
            <div className="oth-field">
              <div className="field-with-btc">{fiatToCryptoRate?.crypto}</div>
              <input type="text" value={cryptoAmount} readOnly />
            </div>
          </div>
          <div className="sec-field">
            <label>{t('ExchangeConfirmation.Rate')}</label>
            <div className="">
              <input type="text" value={rate} readOnly />
            </div>
          </div>
          <div className="sec-field">
            <label>{t('WalletAddress.WalletAddress')}</label>
            <div className="">
              <input type="text" value={wallet?.walletAddress} readOnly />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label className="field-lbl"></label>
            <p style={{ fontSize: 13 }}>{t('FiatToCrypto.QuoteUpdatesIn')} {remainingTime}s</p>
          </div>
        </div>
      </div>
      <div>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <button
              className="purp-btn hug-h continue-fif"
              onClick={submitHandler}
            >
              {t('Common.Continue')} <img src="/img/arrow-right.svg" alt="Arrow Right" />
            </button>
            <div className="police-sub-title">
              {t('FiatToCrypto.AgreePolicyText')}{' '}
              <Link to="/cookie-policy">{t('FiatToCrypto.CookiePolicy')}</Link>
            </div>
          </>
        )}
        {tranIdError && <div className="error-txt">{t('Common.SomethingWentWrong')}</div>}
      </div>

      {transactionIsHolded && <TransactionResultModal/> }
      {transactionRejected && <TransactionRejectModal/> }
    </>
  )
}

export default ExchangeConfirmation;
import './App.css'

import {BrowserRouter, Route, Routes} from 'react-router-dom'

import withAppInsights from './services/AppInsights'

import Main from 'src/components/Main'
import AboutUs from './components/AboutUs'
import RegisterClient from './components/RegisterClient/RegisterClient'
import ClientMail from 'src/components/ClientMail'
import OtpConfirmation from 'src/components/OtpConfirmation'
import {StepGuard} from 'src/components/guards/StepGuard'
import {Step} from 'src/features/steps/stepSlice'
import FiatToCrypto from './components/FiatToCrypto/FiatToCrypto'
import WalletAddress from './components/WalletAddress'
import ExchangeConfirmation from './components/ExchangeConfirmation'
import PaymentResult from 'src/components/PaymentResult'
import Identification from './components/Identification'
import Layout from './components/Layout'
import HelpCenter from './components/HelpCenter'
import BuyCrypto from './components/BuyCrypto'
import Transactions from './components/Transactions'
import ContactUs from './components/ContactUs'
import HelpCenterMobile from './components/HelpCenterMobile'
import TermsOfUse from './components/TermsOfUse'
import PrivacyPolicy from './components/PrivacyPolicy'
import CookiePolicy from './components/CookiePolicy'
import AmlKycPolicy from './components/AmlKycPolicy'
import FiatToCryptoMerchant from './components/FiatToCrypto-Merchant/FiatToCryptoMerchant'
import ExchangeConfirmationMerchant from './components/ExchangeConfirmationMerchant'
import OtpConfirmationMerchant from './components/OtpConfirmationMerchant'
import SignalRContext from 'src/signalRContext'
import {REACT_APP_BACKEND_REALTIME_URL} from 'src/envVariables'
import {useAppSelector} from 'src/hooks/storeHooks'
import FinancialInfo from './components/FinancialInfo/FinancialInfo'
import {useTranslation} from "react-i18next";

const App = () => {
    const currentStep = useAppSelector(store => store.steps.step);
    const {i18n} = useTranslation();

    return (
        <section key={i18n.language}>
            <SignalRContext.Provider
                withCredentials={true}
                connectEnabled={currentStep > 2}
                accessTokenFactory={() => 'Hi'}
                url={REACT_APP_BACKEND_REALTIME_URL}
            >
                <BrowserRouter>
                    <Routes>
                        <Route element={<Main/>}>
                            <Route element={<StepGuard step={Step.Exchange}/>}>
                                <Route path={'/'} element={<FiatToCrypto/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.Mail}/>}>
                                <Route path={'client-mail'} element={<ClientMail/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.OtpConfirmation}/>}>
                                <Route path={'otp-confirmation'} element={<OtpConfirmation/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.UserRegistration}/>}>
                                <Route path={'register-client'} element={<RegisterClient/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.Identification}/>}>
                                <Route path={'identification'} element={<Identification/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.FinancialInfo}/>}>
                                <Route path={'financial-info'} element={<FinancialInfo/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.WalletAddress}/>}>
                                <Route path={'wallet'} element={<WalletAddress/>}/>
                            </Route>
                            <Route element={<StepGuard step={Step.ExchangeConfirmation}/>}>
                                <Route
                                    path={'exchange-confirmation'}
                                    element={<ExchangeConfirmation/>}
                                />
                            </Route>
                            <Route path={'result/:id'} element={<PaymentResult/>}/>
                        </Route>

                        <Route element={<Layout/>}>
                            <Route path="/about-us" element={<AboutUs/>}/>
                            <Route path="/help-center" element={<HelpCenter/>}/>
                            <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/cookie-policy" element={<CookiePolicy/>}/>
                            <Route path="/aml-kyc-policy" element={<AmlKycPolicy/>}/>

                            <Route
                                path={'help-center/:tid/:qid'}
                                element={<HelpCenterMobile/>}
                            />
                            <Route path="/buy-crypto/" element={<BuyCrypto/>}>
                                <Route element={<StepGuard step={Step.Exchange}/>}>
                                    <Route path={':paramId'} element={<FiatToCryptoMerchant/>}/>
                                    <Route path={''} element={<FiatToCrypto/>}/>
                                </Route>
                                <Route element={<StepGuard step={Step.Mail}/>}>
                                    <Route path={'client-mail'} element={<ClientMail/>}/>
                                </Route>
                                <Route element={<StepGuard step={Step.OtpConfirmation}/>}>
                                    <Route
                                        path={'otp-confirmation'}
                                        element={<OtpConfirmationMerchant/>}
                                    />
                                </Route>
                                <Route element={<StepGuard step={Step.Identification}/>}>
                                    <Route path={'identification'} element={<Identification/>}/>
                                </Route>
                                <Route element={<StepGuard step={Step.UserRegistration}/>}>
                                    <Route path={'register-client'} element={<RegisterClient/>}/>
                                </Route>
                                <Route element={<StepGuard step={Step.FinancialInfo}/>}>
                                    <Route path={'financial-info'} element={<FinancialInfo/>}/>
                                </Route>

                                <Route element={<StepGuard step={Step.ExchangeConfirmation}/>}>
                                    <Route
                                        path={'exchange-confirmation'}
                                        element={<ExchangeConfirmationMerchant/>}
                                    />
                                </Route>
                                <Route path={'result/:id'} element={<PaymentResult/>}/>
                            </Route>
                            <Route path="/transactions" element={<Transactions/>}/>
                        </Route>
                        <Route path="/contact-us" element={<ContactUs/>}/>
                    </Routes>
                </BrowserRouter>
            </SignalRContext.Provider>
        </section>
    )
}

export default withAppInsights(App)

import useFetch from 'src/hooks/useFetch'
import {useTranslation} from "react-i18next";

const FooterTop = () => {
  const { data } = useFetch(`/footer-cryptos`);
  const {t} = useTranslation();

  return (
    <div className="footer-top">
      <div className="content">
        <label>{t('FooterTop.BuyQuickly')}</label>
        <div className="swiper mySwiper">
          <div className="swiper-wrapper">
            {data?.map((item: any) => (
              <div className="swiper-slide" key={item.id}>
                <div>{item.attributes.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterTop

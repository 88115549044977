import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/hooks/storeHooks";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { nextStep } from "src/features/steps/stepSlice";
import { initFiatToCryptoRate } from "src/features/transactions/fiatToCryptoRateSlice";
import { initWallet } from "src/features/wallet/walletSlice";
import { getAsync } from "src/api/httpClient";

import Loading from "./Loading/Loading";

import { FiatToCryptoRate } from "src/models/FiatToCrypto";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppSelector } from "src/hooks/storeHooks";
import {useTranslation} from "react-i18next";

const schema = yup.object({
  walletAddress: yup.string().required("Wallet Address is a required field"),
});

type FormInputs = yup.InferType<typeof schema>;

const WalletAddress = () => {
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const currentFTC = useAppSelector((store) => store.fiatToCrypto.fiatToCrypto);

  const fiatToCryptoRate = useAppSelector(
    (store) => store.fiatToCryptoRate.fiatToCryptoRate
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    setIsLoading(true);

    if (!(await walletIsValid(data.walletAddress))) {
      setError(`${t('WalletIsNotValid')}!`);
      setIsLoading(false);
      return;
    }

    await updateFiatToCryptoRate();

    setIsLoading(false);

    dispatch(initWallet({ walletAddress: data.walletAddress }));
    dispatch(nextStep());
    navigate(
      location.pathname.replace(/\/([^\/]+)\/?$/, "/exchange-confirmation")
    );
  };

  const walletIsValid = async (wallet: string) => {
    const response = await getAsync<{ result: boolean }>(
      `transactions/address/validate?crypto=${fiatToCryptoRate?.crypto}&walletAddress=${wallet}`
    );

    return response.data.result;
  };

  const updateFiatToCryptoRate = async () => {
    const fiatToCrypto = await getAsync<FiatToCryptoRate>(
      `rate/fiat-to-crypto?fiat=${currentFTC?.fiatCurrency}&crypto=${currentFTC?.cryptoCurrency}`
    );

    dispatch(initFiatToCryptoRate(fiatToCrypto.data));
  };

  return (
    <div>
      <div className="back-fifth-btn back-btn" onClick={() => navigate(-1)}>
        <img src="/img/arr-left.svg" alt="Arrow Left" />
        {t('WalletAddress.EnterWalletAddress')}
      </div>
      <div className="wallet-title">
        {t('WalletAddress.Warning')}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sec-field">
          <label>{t('WalletAddress.WalletAddress')}</label>
          <div className="field-with-img img-r">
            <input
              {...register("walletAddress")}
              type="text"
              placeholder={t('WalletAddress.EnterWallet')}
            />
          </div>
          {error && <div className="error-txt">{error}</div>}
        </div>

        {isLoading && <Loading />}
        {!isLoading && (
          <button className="purp-btn hug-h continue-s" type={"submit"}>
            {t('Common.Continue')}
            <img src="/img/arrow-right.svg" alt="Arrow Right" />
          </button>
        )}
      </form>
    </div>
  );
};

export default WalletAddress;

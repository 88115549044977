import {useState} from 'react'
import {Await, Link} from 'react-router-dom'
import Select from "react-select/base";
import {useTranslation} from "react-i18next";
import {Switch} from "antd";

const Header: React.FC<{
    showMain?: boolean
}> = ({showMain}) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const {t, i18n} = useTranslation();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen)
    }

    const closeNav = () => {
        setIsNavOpen(false)
    }

    const onLangChange = async (lang: string) => {
        await i18n.changeLanguage(lang.toLowerCase());
    }

    return (
        <div className={showMain ? '' : 'header-bg'}>
            <div className={showMain ? '' : 'content'}>
                <div className="header-line">
                    <Link to="/" className="h-logo" onClick={closeNav}>
                        <img src="/img/logo.svg" alt="Swapilio Logo"/>
                    </Link>
                    <div
                        className={!isNavOpen ? 'burger-menu' : 'change-menu burger-menu'}
                        onClick={toggleNav}
                    >
                        <img src="/img/line.svg" alt="Menu"/>
                        <img src="/img/line.svg" alt="Menu"/>
                    </div>
                    <div className="h-nav desktop-nav">
                        <nav>
                            <Link to="/about-us" className="nav-a">
                                {t('Header.AboutUs')}
                            </Link>
                            <Link to="/contact-us" className="nav-a">
                                {t('Header.Contact')}
                            </Link>
                            <Link to="/help-center" className="nav-a">
                                {t('Header.HelpCenter')}
                            </Link>
                        </nav>
                        <div className="h-button">
                            <Link  to="/buy-crypto" className="fill-btn">
                                {t('Header.BuyCrypto')}
                            </Link>
                        </div>
                        <div style={{float: 'right'}}>
                            <select style={{marginLeft: '10px', width: '60px', height: '30px', textAlign: "center"}} defaultValue={i18n.language === 'ge'? 'GE' : 'EN'} onChange={async (change) => await onLangChange(change.target.value)}>
                                <option value={'EN'}>EN</option>
                                <option value={'GE'}>GE</option>
                            </select>
                        </div>
                    </div>
                    {isNavOpen && (
                        <div className="mobile-nav h-nav">
                            <nav onClick={closeNav}>
                                <Link to="/about-us" className="nav-a">
                                    {t('Header.AboutUs')}
                                </Link>
                                <Link to="/contact-us" className="nav-a">
                                    {t('Header.Contact')}
                                </Link>
                                <Link to="/help-center" className="nav-a">
                                    {t('Header.HelpCenter')}
                                </Link>
                            </nav>
                            <div onClick={closeNav} className="h-button">
                                <Link to="/buy-crypto" className="fill-btn">
                                    {t('Header.BuyCrypto')}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Header

import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {Provider} from "react-redux";
import {store} from "./app/store";
import './i18n';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);


root.render(
    <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
            <App/>
        </Provider>
    </Suspense>
);

reportWebVitals();

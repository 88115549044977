import { REACT_APP_API_URL } from '../envVariables'
import { REACT_APP_API_TOKEN } from '../envVariables'
import i18n from "i18next";
import axios from "axios";

const CMS_URL = REACT_APP_API_URL
const TOKEN = REACT_APP_API_TOKEN


i18n.on('languageChanged', (lng) => {
  lng = lng === 'ge' ? 'ka' : lng;
  makeRequest.defaults.params['locale'] = lng;
});

export const makeRequest = axios.create({
  baseURL: CMS_URL,
  headers: {
    Authorization: "bearer " + TOKEN,
  },
  params:{
    "locale": 'en'
  }
});
